export const defaultTasks = [
  {
    id: 1,
    title: 'aTask',
    description: 'aDescription',
    status: 'todo',
    index: 0,
    mapImg:
      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/5.1101,52.0888,14.36/250x200?access_token=pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw',
    latitude: 52.0836007914832,
    longitude: 5.1300048578897695
  },
  {
    id: 2,
    title: 'bTask',
    description: 'bDescription',
    status: 'in-progress',
    index: 0,
    mapImg:
      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/5.1101,52.0888,14.36/250x200?access_token=pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw',
    latitude: 52.0833300190802,
    longitude: 5.096737344713541
  },
  {
    id: 3,
    title: 'cTask',
    description: 'cDescription',
    status: 'in-progress',
    index: 1,
    mapImg:
      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/5.1101,52.0888,14.36/250x200?access_token=pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw',
    longitude: 5.081315318700865,
    latitude: 52.09267071734868
  },
  {
    id: 4,
    title: 'dTask',
    description: 'cDescription',
    status: 'in-progress',
    index: 2,
    mapImg:
      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/5.1101,52.0888,14.36/250x200?access_token=pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw',
    latitude: 52.10417468687784,
    longitude: 5.089246646389685
  },
  {
    id: 5,
    title: 'eTask',
    description: 'eDescription',
    status: 'completed',
    index: 0,
    mapImg:
      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/5.1101,52.0888,14.36/250x200?access_token=pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw',
    latitude: 52.10674575670229,
    longitude: 5.107753077520431
  }
];
