import React from 'react';
import styled from 'styled-components';

export function Task({ taskId, tasks, setTasks, users, taskUsers, comments, setComments }) {
  const [description, setDescription] = React.useState('');
  const [commentContent, setCommentContent] = React.useState('');
  const task = tasks.find(({ id }) => id === Number(taskId));

  const taskComments = comments.filter(c => c.taskId === task.id);

  React.useEffect(() => {
    setDescription(task.description);
  }, [task.description]);

  if (task) {
    const taskUser = taskUsers.find(findTaskUser => findTaskUser.taskId === task.id);

    function save() {
      setTasks(
        tasks.map(t => {
          if (t.id === task.id) {
            return {
              ...task,
              description
            };
          }
          return t;
        })
      );
    }

    function addComment() {
      setComments([
        ...comments,
        {
          taskId: task.id,
          content: commentContent
        }
      ]);
      setCommentContent('');
    }

    return (
      <div>
        <h2>{task.title}</h2>
        <Status>{task.status}</Status>
        <p>{taskUser ? users.find(user => user.id === taskUser.userId).name : 'No one assigned'}</p>
        <DescriptionTextarea
          onChange={e => setDescription(e.target.value)}
          value={description}></DescriptionTextarea>
        <button onClick={save} type="submit">
          Save
        </button>
        <h3>Comments</h3>
        {taskComments.length === 0 && <p>You can write the first comment!</p>}
        {taskComments.map(comment => (
          <Comment comment={comment} />
        ))}
        <h3>Add comment {commentContent}</h3>
        <CommentTextarea
          onChange={e => setCommentContent(e.target.value)}
          value={commentContent}></CommentTextarea>
        <button onClick={addComment} type="submit">
          Submit
        </button>
      </div>
    );
  }

  return <div>Task not found</div>;
}

function Comment({ comment }) {
  return <p>{comment.content}</p>;
}

const DescriptionTextarea = styled.textarea`
  display: block;
  width: 400px;
  min-height: 200px;
`;

const CommentTextarea = styled.textarea`
  display: block;
  width: 400px;
  min-height: 200px;
`;

const Status = styled.p`
  text-transform: capitalize;
`;
