import React from 'react';
import styled from 'styled-components';
import { Router, Link } from '@reach/router';
import { Kanban } from './kanban';
import { defaultTasks } from './default-tasks';
import { Map } from './map';
import { Task } from './task';

const users = [
  {
    id: 1,
    name: 'Anna',
    avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
  },
  {
    id: 2,
    name: 'Bas',
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
  },
  {
    id: 3,
    name: 'Cas',
    avatar: 'https://randomuser.me/api/portraits/women/3.jpg'
  }
];

const defaultTaskUsers = [
  {
    taskId: 1,
    userId: 1
  }
];

const defaultComments = [
  {
    taskId: 1,
    content: 'Hi'
  }
];

function App() {
  const [tasks, setTasks] = React.useState(defaultTasks);
  const [taskUsers, setTaskUsers] = React.useState(defaultTaskUsers);
  const [comments, setComments] = React.useState(defaultComments);

  return (
    <StyledApp>
      <Top>
        <Link to="/">
          <h1>GeoTask</h1>
        </Link>
      </Top>
      <Container>
        <Kanban
          tasks={tasks}
          taskUsers={taskUsers}
          setTaskUsers={setTaskUsers}
          users={users}
          setTasks={setTasks}
        />
        <Router>
          <Task
            path="tasks/:taskId"
            tasks={tasks}
            setTasks={setTasks}
            taskUsers={taskUsers}
            users={users}
            comments={comments}
            setComments={setComments}
          />
          <Map
            default
            path="/"
            tasks={tasks}
            setTasks={setTasks}
            taskUsers={taskUsers}
            users={users}
          />
        </Router>
      </Container>
    </StyledApp>
  );
}

const StyledApp = styled.div``;

const Top = styled.div``;

const Container = styled.div`
  display: flex;

  .marker {
    background-image: url('mapbox-icon.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export default App;
