export function getUser(taskId, taskUsers, users) {
  const taskUser = taskUsers.find(tu => tu.taskId === taskId);
  if (taskUser) {
    const user = users.find(({ id }) => id === taskUser.userId);
    if (user) {
      return user;
    }
  }
  return undefined;
}

