import React from 'react';
import styled from 'styled-components';
import ReactMapGL, { Marker, Source, Layer } from 'react-map-gl';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser } from './get-user';
const token =
  'pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw';

export function Map({ tasks, setTasks, taskUsers, users }) {
  const [viewport, setViewport] = React.useState({
    width: '1000px',
    height: '800px',
    latitude: 52.0924, // TODO: get lat/long based on tasks
    longitude: 5.1126,
    zoom: 11.64
  });

  function onDragEnd(id) {
    return event => {
      setTasks(
        tasks.map(task => {
          if (task.id === id) {
            console.log(event);
            return {
              ...task,
              latitude: event.lngLat[1],
              longitude: event.lngLat[0]
            };
          }
          return task;
        })
      );
    };
  }

  return (
    <div>
      <ReactMapGL
        mapStyle={'mapbox://styles/mapbox/streets-v11'}
        {...viewport}
        mapboxApiAccessToken={token}
        onViewportChange={setViewport}>
        <Routes tasks={tasks} />
        {tasks.map(task => {
          const user = getUser(task.id, taskUsers, users);
          return (
            <StyledMarker
              className={task.status}
              key={task.id}
              latitude={task.latitude}
              longitude={task.longitude}
              draggable
              onDragEnd={onDragEnd(task.id)}>
              {user && (
                <StyledAvatar
                  src={user.avatar}
                  alt="avatar"
                  draggable={false}
                  onDragStart={e => {
                    e.preventDefault();
                  }}
                />
              )}
              {!user && <DefaultAvatar icon={faUserCircle} size={'lg'} />}
              {task.title}
            </StyledMarker>
          );
        })}
      </ReactMapGL>
    </div>
  );
}

function Routes({ tasks }) {
  const statusList = ['todo', 'in-progress', 'completed'];

  return statusList.map(status => (
    <Source key={status} type="geojson" data={getData(status, tasks)}>
      <Layer {...getDataLayer(status)} />
    </Source>
  ));
}

function getData(status, tasks) {
  const coordinates = tasks
    .filter(task => task.status === status)
    .sort((a, b) => a.index - b.index)
    .map(task => [task.longitude, task.latitude]);

  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: [...coordinates, coordinates[0]]
    }
  };
}

function getDataLayer(status) {
  const colors = {
    todo: 'red',
    'in-progress': 'blue',
    completed: 'green'
  };

  return {
    id: status,
    type: 'line',
    paint: {
      'line-color': colors[status],
      'line-width': 5
    }
  };
}

const StyledMarker = styled(Marker)`
  display: flex;
  height: 20px;
  line-height: 20px;

  &.todo {
    color: red;
  }
  &.in-progress {
    color: blue;
  }
  &.completed {
    color: green;
  }

  &img {
    display: block;
  }
`;

const StyledAvatar = styled.img`
  display: block;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
`;

const DefaultAvatar = styled(FontAwesomeIcon)`
  color: black;
  font-size: 20px;
  margin-right: 5px;
`;
