import { faMinusCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { getUser } from './get-user';

export function Kanban({ tasks, setTasks, taskUsers, setTaskUsers, users }) {
  function onDragEnd(result) {
    if (result.destination) {
      if (result.source.droppableId === result.destination.droppableId) {
        const items = tasks.map(task => Object.assign({}, task));
        const source = items.find(
          task => task.status === result.source.droppableId && task.index === result.source.index
        );
        if (result.destination.index < result.source.index) {
          items
            .filter(
              task =>
                task.status === result.source.droppableId && task.index >= result.destination.index
            )
            .forEach(task => task.index++);
          source.index = result.destination.index;
          setTasks(items);
        } else if (result.destination.index > result.source.index) {
          items
            .filter(
              task =>
                task.status === result.source.droppableId && task.index <= result.destination.index
            )
            .forEach(task => task.index--);
          source.index = result.destination.index;
          setTasks(items);
        }
      } else {
        const items = tasks.map(task => Object.assign({}, task));
        const source = items.find(
          task => task.status === result.source.droppableId && task.index === result.source.index
        );

        items
          .filter(
            task => task.status === result.source.droppableId && task.index > result.source.index
          )
          .forEach(task => task.index--);

        items
          .filter(
            task =>
              task.status === result.destination.droppableId &&
              task.index >= result.destination.index
          )
          .forEach(task => task.index++);
        source.status = result.destination.droppableId;
        source.index = result.destination.index;
        setTasks(items);
      }
    }
  }

  return (
    <>
      <StyledContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Column>
            <h2 className="todo">Todo</h2>
            <TodoColumn
              droppableId="todo"
              tasks={tasks.filter(task => task.status === 'todo').sort((a, b) => a.index - b.index)}
              taskUsers={taskUsers}
              setTaskUsers={setTaskUsers}
              users={users}
            />
          </Column>
          <Column>
            <h2 className="in-progress">In progress</h2>
            <InProgressColumn
              droppableId="in-progress"
              tasks={tasks
                .filter(task => task.status === 'in-progress')
                .sort((a, b) => a.index - b.index)}
              taskUsers={taskUsers}
              setTaskUsers={setTaskUsers}
              users={users}
            />
          </Column>
          <Column>
            <h2 className="completed">Completed</h2>
            <CompletedColumn
              droppableId="completed"
              tasks={tasks
                .filter(task => task.status === 'completed')
                .sort((a, b) => a.index - b.index)}
              taskUsers={taskUsers}
              setTaskUsers={setTaskUsers}
              users={users}
            />
          </Column>
        </DragDropContext>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  h2 {
    text-align: center;
    &.todo {
      color: red;
    }
    &.in-progress {
      color: blue;
    }
    &.completed {
      color: green;
    }
  }
`;

const TodoColumn = styled(DroppableColumn)``;
const InProgressColumn = styled(DroppableColumn)``;
const CompletedColumn = styled(DroppableColumn)``;

function imgUrl(latitude, longitude) {
  return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s(${longitude},${latitude})/${longitude},${latitude},14.36/250x200?access_token=pk.eyJ1IjoibWljaGllbC1tYXBib3giLCJhIjoiY2sydzliZGhiMGNmMzNtbzdpOGhwdW0xbyJ9.Ob35GGzjrfrjg9e8wRN3yw`;
}

function DroppableColumn({ tasks, droppableId, taskUsers, setTaskUsers, users }) {
  function setTaskUser(taskId, userId) {
    const updatedTaskUsers = [...taskUsers.filter(taskUsers => taskUsers.taskId !== taskId)];

    setTaskUsers(userId ? [...updatedTaskUsers, { taskId, userId }] : updatedTaskUsers);
  }

  return (
    <Droppable droppableId={droppableId}>
      {provided => (
        <>
          <StyledColumn {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.map((task, index) => {
              return (
                <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                  {provided2 => {
                    const user = getUser(task.id, taskUsers, users);
                    return (
                      <>
                        <StyledTask
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}>
                          <TaskHeader>
                            <Avatar
                              user={user}
                              users={users}
                              selectUser={userId => setTaskUser(task.id, userId)}
                            />
                            <StyledLink to={`/tasks/${task.id}`}>{task.title}</StyledLink>
                          </TaskHeader>
                          <img src={imgUrl(task.latitude, task.longitude)} alt="map" />
                        </StyledTask>
                      </>
                    );
                  }}
                </Draggable>
              );
            })}
          </StyledColumn>
          {provided.placeholder}
        </>
      )}
    </Droppable>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  &:hover {
    cursor: pointer;
    color: #ffa35c;
    transition: color 150ms;
  }
`;

const StyledColumn = styled.div`
  width: 270px;
  font-size: 30px;
  padding: 10px;
  flex-grow: 1;
`;

const StyledTask = styled.div`
  padding: 10px;
  img {
    display: block;
  }
`;

const TaskHeader = styled.div`
  display: flex;
`;

function useOutsideAlerter(ref, showSelector, setShow) {
  useEffect(() => {
    if (showSelector) {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }
  }, [showSelector, ref, setShow]);
}

function Avatar({ user, users, selectUser }) {
  const [showSelector, setShow] = React.useState(false);

  function onClick() {
    setShow(!showSelector);
  }

  return (
    <>
      <Selector
        showSelector={showSelector}
        setShow={setShow}
        user={user}
        users={users}
        selectUser={userId => {
          setShow(false);
          selectUser(userId);
        }}
      />
      {user ? (
        <StyledAvatar onClick={onClick} src={user.avatar} alt={`Avatar of ${user.name}`} />
      ) : (
        <DefaultAvatar onClick={onClick} icon={faUserCircle} size={'lg'} />
      )}
    </>
  );
}

function Selector({ showSelector, setShow, user, users, selectUser }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, showSelector, setShow);

  return (
    <StyledSelector showSelector={showSelector}>
      <div ref={wrapperRef}>
        {users
          .filter(filterUser => user === undefined || filterUser.id !== user.id)
          .map(mapUser => (
            <SelectorUser key={mapUser.id} onClick={() => selectUser(mapUser.id)}>
              <StyledAvatar src={mapUser.avatar} alt="avatar" />
              {mapUser.name}
            </SelectorUser>
          ))}
        {user && (
          <SelectorUser onClick={() => selectUser(null)}>
            <DefaultAvatar icon={faMinusCircle} size={'lg'} />
            Unassign
          </SelectorUser>
        )}
      </div>
    </StyledSelector>
  );
}

const StyledSelector = styled.div`
  display: ${props => (props.showSelector ? 'block' : 'none')};
  position: absolute;
  opacity: ${props => (props.showSelector ? 0.85 : 0)};
  margin-top: 50px;
  margin-left: -10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 11px 0;
  font-size: 20px;
  overflow: hidden;
`;

const SelectorUser = styled.div`
  display: flex;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    cursor: pointer;
    background-color: #ece7e1;
  }
`;

const DefaultAvatar = styled(FontAwesomeIcon)`
  font-size: 40px;
  margin-right: 10px;
  border: 2px solid transparent;
  &:hover {
    border-radius: 50%;
    border: 2px solid #ffa35c;
  }
`;

const StyledAvatar = styled.img`
  display: block;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid transparent;
  &:hover {
    border-radius: 20px;
    border: 2px solid #ffa35c;
    cursor: pointer;
  }
`;
